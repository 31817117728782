import type { FunctionComponent } from 'react'
import tw from 'twin.macro'
import { GridWithGaps } from '../index'
import { useNewsletterForm } from './useNewsletterForm'

const StyledNewsletterForm = tw.div`col-span-full`

const StyledEmailField = tw.div`col-span-full md:col-span-8 ml:col-span-6`
const StyledLegalImprintField = tw.div`col-span-full mt-px-32 ml:col-span-8 `
const StyledSubmitButton = tw.div`col-span-full mt-px-32`

export const NewsletterForm: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const {
    EmailField,
    LegalImprintField,
    SubmitButton,
    FormProvider,
    formProps,
    formProviderProps,
    SuccessDialog,
  } = useNewsletterForm()
  return (
    <StyledNewsletterForm>
      <FormProvider {...formProviderProps}>
        <form {...formProps}>
          <GridWithGaps>
            <StyledEmailField>{EmailField}</StyledEmailField>
            <StyledLegalImprintField>
              {LegalImprintField}
            </StyledLegalImprintField>
            <StyledSubmitButton>{SubmitButton}</StyledSubmitButton>
          </GridWithGaps>
        </form>
      </FormProvider>
      {SuccessDialog}
    </StyledNewsletterForm>
  )
}
