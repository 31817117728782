import { GridWithGaps } from '@dreamstack/feature-components'
import { NewsletterForm } from '@dreamstack/feature-components/src/newsletter/NewsletterForm'
import type { FunctionComponent } from 'react'
import tw, { styled } from 'twin.macro'

const FullWidthBackgroundContainerExtraMargins = styled.div(() => [
  tw`bg-accentroGray-full z-index[-1] width[100vw] absolute left-1/2 right-1/2 margin-left[-50vw] margin-right[-50vw] 
  height[calc(100% + 96px)] top[-48px] bottom[-48px] md:(top[-96px] bottom[-96px] height[calc(100% + 192px)]) lg:(top[-128px] bottom[-128px] height[calc(100% + 256px)])`,
])

const NewsletterWrapper = tw.div`bg-accentroGray-full text-accentroWhite-full relative`
const Spacing = tw.div`margin-top[112px] margin-bottom[112px] md:(margin-top[192px] margin-bottom[192px]) lg:(margin-top[224px] margin-bottom[224px])`

export const NewsletterCustom: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  return (
    <NewsletterWrapper>
      <Spacing>
        <FullWidthBackgroundContainerExtraMargins />
        <GridWithGaps>
          <NewsletterForm />
        </GridWithGaps>
      </Spacing>
    </NewsletterWrapper>
  )
}
